<template>
  <div class="wraper">
    <div class="outContent">
      

      <img class="showLogo" src="../assets/img/showLogo.png" alt="" />

      <div class="not_speck" v-if="isWeixinData == true"></div>

      <img
        class="right_go"
        src="../assets/img/go.png"
        alt=""
        v-if="isWeixinData == true"
      />
      <img
        class="world"
        src="../assets/img/world.png"
        alt=""
        v-if="isWeixinData == true"
      />

      <a
        href="tbz://"
        class="showOpenButtom"
        v-if="androidIos == 'ios'"
        @click="downShow"
        >立即打开客户端</a
      >

      <a
        href="tbz://h5/home"
        class="showOpenButtom"
        v-if="androidIos == 'android'"
        @click="downShowAndroid"
        >立即打开客户端</a
      >
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import VueClipboard from 'vue-clipboard2'
export default {
  name: "Home",
  components: {},
  data() {
    return {
      isWeixinData: false,
      androidIos: "",
    };
  },

  created: function () {
    var ua = navigator.userAgent.toLowerCase();
    var isWeixin = ua.indexOf("micromessenger") != -1;

    this.isWeixinData = isWeixin;

    var isFalse = true;
    
    var us = navigator.userAgent;
    let isAndroid = us.indexOf("Android") > -1 || us.indexOf("Linux") > -1; //g
    let isIOS = !!us.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端

    if (isAndroid) {
      isFalse = false;
    } else if (isIOS) {
      isFalse = true;
    }
    this.androidIos = isFalse ? "ios" : "android";
    // alert(isAndroid + "请下载APP" + isIOS);
    // alert(this.androidIos + "请下载APP" + this.androidIos);
  },

  // v-clipboard:copy="asdasasdasda"
  methods: {
    openShow() {
      var ua = navigator.userAgent.toLowerCase();
      var isWeixin = ua.indexOf("micromessenger") != -1;
      var isAndroid = ua.indexOf("android") != -1;
      var isIos = ua.indexOf("iphone") != -1 || ua.indexOf("ipad") != -1;
      var d = new Date();
      var t0 = d.getTime();
      $(function () {
        //判断执行安卓按钮还是苹果按钮
        if (isAndroid) {
        } else {
        }
      });
    },

    copy() {
      var Input = document.createElement("input"); //创建一个隐藏input（重要！）
      Input.value = "666666"; //赋值
      document.body.appendChild(Input);
      Input.select(); // 选择对象
      document.execCommand("Copy"); // 执行浏览器复制命令
      Input.className = "oInput";
      Input.style.display = "none";
      // this.$message({ message: "复制成功", type: "success" });
    },

    // android下载链接
    downShowAndroid() {
      var ua = navigator.userAgent.toLowerCase();
      var isWeixin = ua.indexOf("micromessenger") != -1;
      if (isWeixin) {
        alert("请在浏览器中打开");
        return true;
      }
      const initialTime = new Date();
      let counter = 0;
      let waitTime = 0;
      const checkOpen = setInterval(() => {
        counter++;
        waitTime = new Date() - initialTime;
        if (waitTime > 3000) {
          clearInterval(checkOpen);
        }
        if (counter < 100) return;
        const hide = document.hidden || document.webkitHidden;
        if (!hide) {
          alert("请下载APP");
          window.location.href = "http://backend.tuanxo.com/tbz.apk";
        }
      }, 20);
    },

    downShow() {
      var ua = navigator.userAgent.toLowerCase();
      var isWeixin = ua.indexOf("micromessenger") != -1;
      if (isWeixin) {
        alert("请在浏览器中打开");
        return true;
      }

      let username = this.$route.query.username;
      console.log(username);
      console.log("11111111");
      // function openApp(src) {
      // 通过iframe的方式试图打开APP，如果能正常打开，会直接切换到APP，并自动阻止a标签的默认行为
      // 否则打开a标签的href链接

      var Input = document.createElement("input"); //创建一个隐藏input（重要！）
      Input.value = "tbz://invite=" + username; //赋值
      document.body.appendChild(Input);
      Input.select(); // 选择对象
      document.execCommand("Copy"); // 执行浏览器复制命令
      Input.className = "oInput";
      Input.style.display = "none";
      // this.$message({ message: "复制成功", type: "success" });

      const initialTime = new Date();
      let counter = 0;
      let waitTime = 0;
      const checkOpen = setInterval(() => {
        counter++;
        waitTime = new Date() - initialTime;
        if (waitTime > 3000) {
          clearInterval(checkOpen);
        }
        if (counter < 100) return;
        const hide = document.hidden || document.webkitHidden;
        if (!hide) {
          alert("请下载APP");
          window.location.href = "https://apps.apple.com/cn/app/id1537712876";
        }
      }, 20);

      // var d = new Date();
      // var t0 = d.getTime();
      // var delay = setInterval(function () {
      //   var d = new Date();
      //   var t1 = d.getTime();
      //   if (t1 - t0 < 3000 && t1 - t0 > 2000) {
      //     alert("请下载APP");
      //     window.location.href = "https://apps.apple.com/cn/app/id1537712876";
      //   }
      //   if (t1 - t0 >= 3000) {
      //     clearInterval(delay);
      //   }
      // }, 2000);
    },
    outHide() {
      console.log("22222222");
      this.whatShowBlock = 2;
    },
  },
};
</script>
<style scoped>
.wraper {
  width: 100%;
  height: 100vh;
  background: #ffffff;
  position: relative;
}

.outContent {
  width: 100%;
  height: 100%;
}

.typeImg {
  width: 100%;
  height: 1634px;
}

.showLogo {
  top: 1200px;
  z-index: 111;
  position: absolute;
  width: 32vw;
  height: 32vw;
  left: 34vw;
}

.not_speck {
  z-index: 1111;
  top: 0px;
  position: absolute;
  width: 100%;
  height: 100%;
  background: #000000;
  opacity: 0.6;
}

.right_go {
  z-index: 11111;
  width: 10vw;
  right: 10vw;
  top: 3vw;
  height: 20vw;
  position: absolute;
}

.world {
  z-index: 11111;
  height: 13vw;
  width: 40vw;
  right: 13vw;
  top: 23vw;
  position: absolute;
}

/*       <img class="right_go" src="../assets/img/go.png" alt="" />
      <img class="world" src="../assets/img/world.png" alt="" />
 */

.showOpenButtom {
  display: inline-block;
  margin-top: 500px;
  width: 90%;
  height: 250px;
  background: #882fea;
  text-align: center;
  line-height: 250px;
  font-size: 70px;
  font-weight: 800;
  color: #ffffff;
  border-radius: 18px;
}

/* <div class="outContent">
      
      
      <div class="showOpenButtom">立即下载商户端</div>

    </div> */

.headercon {
  height: 72px;
  background: #6932d4;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 262px;
}

.logo {
  width: 326px;
  height: 54px;
}

.tabCon {
  width: 1023px;
  display: flex;
  height: 72px;
  align-items: center;
  justify-content: flex-end;
  font-size: 16px;
}

.isactive {
  color: #fff;
  /* padding: 0 5px; */
  border-bottom: 2px solid rgba(255, 255, 255, 0.6);
}

.tabItem {
  display: inline-block;
  height: 70px;
  margin-left: 46px;
  color: #fff;
  padding: 0 5px;
  line-height: 70px;
  text-align: center;
  color: rgba(255, 255, 255, 0.8);
  cursor: pointer;
}

.container {
  /* width:100%; */
  width: 1920px;
  /* height: 1080px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.webCon,
.webCon3 {
  width: 1920px;
  height: 1080px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.webCon3 {
  width: 1920px;
  height: 1080px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.downs {
  width: 80px;
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: rgba(255, 255, 255, 0.8);
  font-size: 16px;
  cursor: pointer;
  margin-left: 46px;
  background: #6932d4;
}
.downsspan {
  display: inline-block;
  width: 32px;
}

.icons {
  width: 50%;
}
.webCon3 {
  background: url(../assets/img/bgs.png) no-repeat top center;
  background-size: 100%;
}

.imgbg {
  width: 100%;
}

.video {
  position: relative;
  margin-top: -250px;
  /* margin-top: 50px; */
  width: 1080;
  height: 720px;
}

.videos {
  /* margin-top: 50px; */
  width: 1080;
  height: 720px;
}

.show_block {
  top: 50px;
  right: 200px;
  position: absolute;
  justify-content: center;
  align-items: center;
  display: flex;
  width: 140px;
  height: 130px;
  background: #ffffff;
  border-radius: 8px;
}

.codePhone {
  width: 100px;
  height: 100px;
}

.codeMini {
  margin-left: 8px;
  width: 120px;
  height: 120px;
}

.stopImg {
  display: block;
  left: 580px;
  top: 300px;
  position: absolute;
  width: 110px;
  height: 110px;
}

.plaryVideoCss {
  top: 0px;
  left: 0px;
  position: absolute;
  width: 1080;
  height: 720px;
}
</style>
